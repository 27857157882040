import React, { useEffect, useRef } from 'react';

import {
  Root,
  Container,
  Heading,
  HeadingImg,
  HeadingText,
  Overlay,
} from './Hero.style';

import LogoMinSrc from '../../images/logo-min.svg';

const Hero = ({ img, title }) => {

  const headingRef = useRef(null);
  useEffect(() => {
    if (headingRef?.current) {
      headingRef.current.focus();
    }
  }, [title]);

  return (
    <Root $bg={img}>
      <Overlay>
        <Container>
          <Heading>
            <HeadingImg src={LogoMinSrc} alt="" />
            <HeadingText ref={headingRef} tabIndex="-1">
              {title}
            </HeadingText>
          </Heading>
        </Container>
      </Overlay>
    </Root>
  );
};

export default Hero;
