import styled, { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 66px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 1023px) {
    height: 460px;
    background-image: ${p =>
      p.$bg.mobile ? `url(${p.$bg.mobile})` : `url(${p.$bg.desktop})`};
  }

  @media (min-width: 1024px) {
    height: 640px;
    background-image: ${p => `url(${p.$bg.desktop})`};
  }
`;

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (min-width: 1024px) {
   background: linear-gradient(135deg, #fff0, #000A);
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1127px;
  height: 100%;

  @media (min-width: 1024px) {
    padding: 0 24px;
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  opacity: 0;
  animation-delay: 250ms;
  animation-duration: 250ms;
  animation-name: ${fadeIn};
  animation-fill-mode: forwards;

  @media (max-width: 1023px) {
    display: none;
  }
`;

export const HeadingImg = styled.img`
  width: 41px;
  height: 33px;
`;

export const HeadingText = styled.h1`
  margin-top: 20px;
  padding-top: 17px;
  color: #fff;
  font-family: 'GreatWestern';
  font-size: 20px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-top: 1px solid #fff;

  writing-mode: vertical-rl;
  text-orientation: mixed;
  outline:none;
`;
